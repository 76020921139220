<template>
  <div>
    <el-row class="header-row">
      <el-col :span="24">
        <h3 class="el-page-header">Professional Fee Risk Analysis</h3>
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/pro-risk-analysis' }">Professional Fee Risk Analysis</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <div>
      <el-card style="overflow: unset;">
        <el-row :gutter="20">
          <el-col :span="5" style="border-right: 1px solid #eee;" class="sticky">
            <div style="margin-bottom: 20px;">
              <div style="margin-bottom: 5px; font-size: 14px;"><i title="Filter By Date Range" class="mdi mdi-filter-variant filterIcon"></i><label>Filter Date Range:</label>
              </div>
              <el-date-picker class="hiaRangePicker" size="mini" format="MM/dd/yyyy" value-format="MM/dd/yyyy" v-model="localdateRange" type="daterange" range-separator="To"
                start-placeholder="Start date" end-placeholder="End date" @change="handleCriteriaChange" style="z-index: 1;" :picker-options="dateRangePickerConfig" clearable>
              </el-date-picker>
            </div>
            <div style="margin-bottom: 5px; font-size: 14px;"><i title="Filter By EM Category" class="mdi mdi-filter-variant filterIcon"></i><label>Filter EM Category:</label>
            </div>
            <div style="height: 350px; overflow:hidden; overflow-y: auto; padding: 0px 5px 0px 0px;margin: 0px 0px 20px 0px;" element-loading-spinner="atom-audit-loader"
              v-loading="categoryDataLoading">
              <div class="category-item" :class="{ 'category-item-active': category.category === emCategorySelect }" v-for="category in categoryData" :key="category.category"
                @click="handleEmCategorySelect(category.category)">
                <div style="margin-bottom: 5px;">{{ category.category }}</div>
                <el-row :gutter="10">
                  <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                    <span style="font-style:italic; margin: 0px 0px 0px 0px;font-size: 12px;"><i class="mdi mdi-numeric"></i> {{ category.numberOfEmCodes }} EM Codes</span>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                    <span style="font-style:italic; margin: 0px 0px 0px 10px;font-size: 12px;"><i class="el-icon-user"></i> {{ category.numberOfProviders }} Providers</span>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                    <span style="font-style:italic; margin: 0px 0px 0px 10px;font-size: 12px;"><i class="el-icon-star-off"></i> {{ category.numberOfSpecialties }}
                      Specialties</span>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                    <span style="font-style:italic; margin: 0px 0px 0px 10px;font-size: 12px;"><i class="el-icon-location-outline"></i> {{ category.numberOfServiceLocations }}
                      Locations</span>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-row style="margin-bottom: 20px;">
              <div style="margin-bottom: 5px; font-size: 14px;">
                <i title="Filter By Provider and Specialties" class="mdi mdi-filter-variant filterIcon"></i><label>Filter By:</label>
                <el-dropdown style="float: right;" trigger="click" @command="handleFilterCommands">
                  <span><i title="filter actions" class="actionsAction mdi mdi-dots-horizontal" style="font-size: 18px; cursor: pointer;"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="clear">Clear Filters</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div style="margin: 10px 0px 0px 5px;font-size: 13px; display: flex;">
                <!-- Providers -->
                <div class="specialtiesFilterContainer">
                  <span v-if="!this.providersFilter.length"><i style="margin: 0px 5px 0px 5px;font-size: 14px; font-weight: 100; color: #b4bccc;"
                      class="el-icon-user"></i><span style="color: #b4bccc;">Providers</span></span>
                  <span v-html="compProvidersFilterTitle"></span>
                </div>
                <div class="specialtiesFilterButton">
                  <el-button size="mini" title="add values" icon="el-icon-search" @click="openProviderFilters()"></el-button>
                </div>
              </div>
              <div style="margin: 10px 0px 0px 5px;font-size: 13px; display: flex;">
                <!-- Specialties -->
                <div class="specialtiesFilterContainer">
                  <span v-if="!this.providerSpecialtiesFilter.length"><i style="margin: 0px 5px 0px 5px;font-size: 14px; font-weight: 100; color: #b4bccc;"
                      class="el-icon-star-off"></i><span style="color: #b4bccc;">Specialties</span></span>
                  <span v-html="compFilterTitle"></span>
                </div>
                <div class="specialtiesFilterButton">
                  <el-button size="mini" title="add values" icon="el-icon-search" @click="openSpecialtiesFilters()"></el-button>
                </div>
              </div>
              <div style="margin: 10px 0px 0px 5px;font-size: 13px; display: flex;">
                <!-- Service Locations -->
                <div class="specialtiesFilterContainer">
                  <span v-if="!this.serviceLocationsFilter.length"><i style="margin: 0px 5px 0px 5px;font-size: 14px; font-weight: 100; color: #b4bccc;"
                      class="el-icon-location-outline"></i><span style="color: #b4bccc;">Service Locations</span></span>
                  <span v-html="compServiceLocationsFilterTitle"></span>
                </div>
                <div class="specialtiesFilterButton">
                  <el-button size="mini" title="add values" icon="el-icon-search" @click="openServiceLocationsFilters()"></el-button>
                </div>
              </div>
            </el-row>
            <el-row>
              <div style="margin-bottom: 0px; font-size: 14px;">
                <i title="Select Comparisons" class="mdi mdi-set-none filterIcon"></i><label>Select Comparisons:</label>
                <el-dropdown style="float: right;" trigger="click" @command="handleComparisonCommands">
                  <span><i title="filter actions" class="actionsAction mdi mdi-dots-horizontal" style="font-size: 18px; cursor: pointer;"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="clear">Clear Comparisons</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div style="margin: 10px 0px 0px 0px;font-size: 13px;">
                <input type="checkbox" v-model="medicareCompare" />
                Medicare Compare <span style="font-size: 20px;color:#fee18f;margin: -1px 0px 0px 3px;position:absolute;">●</span>
              </div>
              <div style="font-size: 13px;">
                <input type="checkbox" v-model="specialtyCompare" />
                Provider Specialty Compare <span style="font-size: 20px;color:#f57fc5;margin: -1px 0px 0px 3px;position:absolute;">●</span>
              </div>
              <div style="font-size: 13px;">
                <input type="checkbox" v-model="providerSpecialtiesCompare" @change="
                    () => {
                      if (!providerSpecialtiesCompare) {
                        this.providerSpecialtiesSelect = []
                        this.handleProviderSpecialtiesSelect()
                      }
                    }
                  " />
                Specialties Compare <span style="font-size: 20px;color:#7cbe5f;margin: -1px 0px 0px 3px;position:absolute;">●</span>
              </div>
              <div style="margin-top: 10px; margin-left: 5px;display: flex;" v-show="providerSpecialtiesCompare">
                <div class="specialtiesFilterContainer">
                  <span v-if="!this.providerSpecialtiesSelect.length"><i style="margin: 0px 5px 0px 5px;font-size: 14px; font-weight: 100; color: #b4bccc;"
                      class="el-icon-star-off"></i><span style="color: #b4bccc;">Specialties</span></span>
                  <span v-html="compTitle"></span>
                </div>
                <div class="specialtiesFilterButton">
                  <el-button size="mini" title="add values" icon="el-icon-search" @click="openSpecialtiesCompare()"></el-button>
                </div>
              </div>
            </el-row>
          </el-col>
          <el-col :span="19">
            <el-row class="mainDataRow">
              <template>
                <el-row>
                  <!--  data tabs -->
                  <div class="listOptions">
                    <div style="top: 25%; position: relative;">
                      <el-row :gutter="2">
                        <el-col :span="24">
                          <el-input v-show="this.activeTab === 'distributionByProvider'" size="mini" placeholder="filter all..." suffix-icon="el-icon-search"
                            v-model="providerGlobalSearch" @input="runGridFilters($event)"> </el-input>
                          <el-input v-show="this.activeTab === 'distributionBySpecialty'" size="mini" placeholder="filter all..." suffix-icon="el-icon-search"
                            v-model="providerSpecialtyGlobalSearch" @input="runGridFilters($event)"> </el-input>
                          <el-input v-show="this.activeTab === 'distributionByServiceLocation'" size="mini" placeholder="filter all..." suffix-icon="el-icon-search"
                            v-model="serviceLocationGlobalSearch" @input="runGridFilters($event)"> </el-input>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                  <el-tabs class="dataTabs" v-model="activeTab">
                    <el-tab-pane label="Distribution by Provider" name="distributionByProvider">
                      <v-server-table style="min-height: 120px;" ref="distributionProvidersGrid" id="distributionProvidersGrid" :columns="providerColumns"
                        :options="providerOptions" class="hiaTable" element-loading-spinner="atom-audit-loader" @loading="providerTableLoader = true"
                        @loaded="providerTableLoader = false" v-loading="providerTableLoader">
                        <template slot="seriesChart" slot-scope="props">
                          <el-row>
                            <el-col :span="12">
                              <div style="display: flex; justify-content: space-between; align-items: center; margin: 5px 0px 0px 5px;">
                                <div style="font-size: 16px; flex-grow: 1;">
                                  <div style="font-size: 16px;margin: 0px 0px 15px 0px;"><i title="Provider" class="el-icon-user" style="padding-right: 5px;"></i>{{
                                    props.row.providerName }}</div>
                                  <div style="font-size: 16px;margin: 0px 0px 0px 0px;">
                                    <i title="Specialty" class="el-icon-star-off" style="padding-right: 5px;"></i>
                                    <span v-if="props.row.providerClassification"> {{ props.row.providerClassification }} / </span>
                                    <span v-else> <i>No Classification </i> / </span>
                                    <span v-if="props.row.providerSpecialty">
                                      {{ props.row.providerSpecialty }}
                                    </span>
                                    <span v-else>
                                      <i>No Specialty </i>
                                    </span>
                                  </div>
                                </div>
                                <el-button class="hiaButton" style="float: right; margin-top: -22px;" plain round size="mini" icon="el-icon-zoom-out"
                                  @click="handleShowModal(props.row)">Provider</el-button>
                              </div>
                            </el-col>
                            <el-col :span="12"> </el-col>
                          </el-row>
                          <el-row style="margin: 15px 0px 10px 7px;">
                            <el-col :span="12">
                              <FrequencyTable :seriesData="props.row.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="specialtyCompare"
                                :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length"></FrequencyTable>
                            </el-col>
                            <el-col :span="12">
                              <ProRiskAnalysisChart :seriesData="props.row.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="specialtyCompare"
                                :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length" :type="'provider'"
                                :providerName="props.row.providerName"></ProRiskAnalysisChart>
                            </el-col>
                          </el-row>
                        </template>
                      </v-server-table>
                    </el-tab-pane>

                    <el-tab-pane label="Distribution by Specialty" name="distributionBySpecialty" lazy>
                      <v-server-table style="min-height: 120px;" ref="distributionSpecialtiesGrid" id="distributionSpecialtiesGrid" :columns="specialtyColumns"
                        :options="specialtyOptions" class="hiaTable" element-loading-spinner="atom-audit-loader" @loading="specialtyTableLoader = true"
                        @loaded="specialtyTableLoader = false" v-loading="specialtyTableLoader">
                        <template slot="seriesChart" slot-scope="props">
                          <el-row>
                            <el-col :span="12">
                              <div style="display: flex; justify-content: space-between; align-items: center; margin: 5px 0px 0px 5px;">
                                <div style="font-size: 16px; flex-grow: 1;">
                                  <i title="Specialty" class="el-icon-star-off" style="padding-right: 5px;"></i>
                                  <span v-if="props.row.providerClassification"> {{ props.row.providerClassification }} / </span>
                                  <span v-else> <i>No Classification </i> / </span>
                                  <span v-if="props.row.providerSpecialty">
                                    {{ props.row.providerSpecialty }}
                                  </span>
                                  <span v-else>
                                    <i>No Specialty </i>
                                  </span>
                                </div>
                                <el-button class="hiaButton" style="margin-left: 10px;" plain round size="mini" icon="el-icon-zoom-out"
                                  @click="handleShowModal(props.row)">Specialty</el-button>
                              </div>
                            </el-col>
                            <el-col :span="12"> </el-col>
                          </el-row>
                          <el-row style="margin: 15px 0px 10px 7px;">
                            <el-col :span="12">
                              <FrequencyTable :seriesData="props.row.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="false"
                                :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length"></FrequencyTable>
                            </el-col>
                            <el-col :span="12">
                              <ProRiskAnalysisChart :seriesData="props.row.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="false"
                                :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length" :type="'provider'"
                                :providerName="props.row.providerSpecialty || props.row.providerClassification"></ProRiskAnalysisChart>
                            </el-col>
                          </el-row>
                        </template>
                      </v-server-table>
                    </el-tab-pane>
                    <el-tab-pane label="Distribution by Service Location" name="distributionByServiceLocation" lazy>
                      <v-server-table style="min-height: 120px;" ref="distributionServiceLocationGrid" id="distributionServiceLocationGrid" :columns="serviceLocationColumns"
                        :options="serviceLocationOptions" class="hiaTable" element-loading-spinner="atom-audit-loader" @loading="serviceLocationTableLoader = true"
                        @loaded="serviceLocationTableLoader = false" v-loading="serviceLocationTableLoader">
                        <template slot="seriesChart" slot-scope="props">
                          <el-row>
                            <el-col :span="12">
                              <div style="display: flex; justify-content: space-between; align-items: center; margin: 5px 0px 0px 5px;">
                                <div style="font-size: 16px; flex-grow: 1;">
                                  <div style="font-size: 16px;margin: 0px 0px 15px 0px;"><i title="Service Location" class="el-icon-location-outline"
                                      style="padding-right: 5px;"></i>{{ props.row.serviceLocation }}</div>
                                </div>
                                <el-button class="hiaButton" style="float: right; margin-top: -22px;" plain round size="mini" icon="el-icon-zoom-out"
                                  @click="handleShowModal(props.row)">Service Location</el-button>
                              </div>
                            </el-col>
                            <el-col :span="12"> </el-col>
                          </el-row>
                          <el-row style="margin: 15px 0px 10px 7px;">
                            <el-col :span="12">
                              <FrequencyTable :seriesData="props.row.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="false"
                                :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length"></FrequencyTable>
                            </el-col>
                            <el-col :span="12">
                              <ProRiskAnalysisChart :seriesData="props.row.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="false"
                                :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length" :type="'provider'"
                                :providerName="props.row.serviceLocation"></ProRiskAnalysisChart>
                            </el-col>
                          </el-row>
                        </template>
                      </v-server-table>
                    </el-tab-pane>
                    <el-tab-pane label="Overall Distribution" name="overallDistribution" lazy>
                      <div style="margin: 0px 0px 70px 0px;" element-loading-spinner="atom-audit-loader" v-loading="overallLoading">
                        <el-row v-if="!overallDistribution.count" style="opacity: .2;">
                          <h3 style="margin: 0; padding: 0;">Overall Distribution {{ emCategorySelect ? ` for ${emCategorySelect}` : `` }}</h3>
                          <el-row style="margin: 20px 0px 50px 0px;">
                            <el-col :span="12">
                              <FrequencyTable :seriesData="overallDistribution.data.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="false"
                                :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length"></FrequencyTable>
                            </el-col>
                            <el-col :span="12">
                              <ProRiskAnalysisChart :seriesData="overallDistribution.data.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="false"
                                :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length" :type="'provider'" :providerName="'Overall'">
                              </ProRiskAnalysisChart>
                            </el-col>
                          </el-row>
                        </el-row>

                        <el-row v-show="overallDistribution.count">
                          <h3 style="margin: 0; padding: 0;float: left;">Overall Distribution {{ emCategorySelect ? ` for ${emCategorySelect}` : `` }}</h3>
                          <el-button class="hiaButton" style="margin-right: 10px; margin-top: -20px; margin-left: 20px;" plain round size="mini" icon="el-icon-zoom-out"
                            @click="handleShowModalAll()">Overall</el-button>
                        </el-row>
                        <el-row style="margin: 20px 0px 0px 0px;" v-show="overallDistribution.count">
                          <el-col :span="12">
                            <FrequencyTable :seriesData="overallDistribution.data.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="false"
                              :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length"></FrequencyTable>
                          </el-col>
                          <el-col :span="12">
                            <ProRiskAnalysisChart :seriesData="overallDistribution.data.seriesData" :medicareCompare="medicareCompare" :specialtyCompare="false"
                              :providerSpecialtiesCompare="providerSpecialtiesCompare && providerSpecialtiesSelect.length" :type="'provider'" :providerName="'Overall'">
                            </ProRiskAnalysisChart>
                          </el-col>
                        </el-row>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </el-row>
              </template>
            </el-row>
          </el-col>
        </el-row>
      </el-card>
    </div>

    <div id="dialogs">
      <SpecialtiesCompareDialog v-if="showSpecialtiesCompareModal" :tile="'Select Specialties to Compare'" :showModal="showSpecialtiesCompareModal"
        @closeModal="handleCloseSpecialtiesModal" :specialties="specialtiesSelectList" @applySpecialties="handleApplySpecialtiesCompare"
        :providerSpecialtiesSelect="providerSpecialtiesSelect" :propertyValue="'providerTaxonomy'" :loading="filterDataLoading"> </SpecialtiesCompareDialog>
      <SpecialtiesCompareDialog v-if="showSpecialtiesCompareModalByType" :tile="'Select Specialties to Compare'" :showModal="showSpecialtiesCompareModalByType"
        @closeModal="handleCloseSpecialtiesModalByType" :specialties="specialtiesSelectList" @applySpecialties="handleApplySpecialtiesCompareByType"
        :providerSpecialtiesSelect="providerSpecialtiesSelectModal" :propertyValue="'providerTaxonomy'" :loading="filterDataLoading"> </SpecialtiesCompareDialog>
      <!-- filters -->
      <SpecialtiesCompareDialog v-if="showProviderSpecialtiesFilterModal" :tile="'Select Specialties to Filter'" :showModal="showProviderSpecialtiesFilterModal"
        @closeModal="handleCloseProviderSpecialtiesFilter" :specialties="specialtiesSelectList" @applySpecialties="handleApplyProviderSpecialtiesFilter"
        :providerSpecialtiesSelect="providerSpecialtiesFilter" :propertyValue="'providerTaxonomy'" :loading="filterDataLoading"> </SpecialtiesCompareDialog>
      <SpecialtiesCompareDialog v-if="showProvidersFilterModal" :tile="'Select Providers to Filter'" :showModal="showProvidersFilterModal"
        @closeModal="handleCloseProvidersFilter" :specialties="providersSelectList" @applySpecialties="handleApplyProvidersFilter" :providerSpecialtiesSelect="providersFilter"
        :propertyValue="'providerName'" :loading="filterDataLoading"> </SpecialtiesCompareDialog>
      <SpecialtiesCompareDialog v-if="showLocationsFilterModal" :tile="'Select Service Locations to Filter'" :showModal="showLocationsFilterModal"
        @closeModal="handleCloseLocationsFilter" :specialties="locationsSelectList" @applySpecialties="handleApplyLocationsFilter"
        :providerSpecialtiesSelect="serviceLocationsFilter" :propertyValue="'serviceLocation'" :loading="filterDataLoading"> </SpecialtiesCompareDialog>
      <el-dialog v-if="showModal" width="97%" top="2vh"
        :title="`Risk Analysis for ${activeTab === 'distributionByProvider' ? `${modalData.providerName} - ${modalData.providerSpecialty}` : activeTab === 'distributionByServiceLocation' ? modalData.serviceLocation : modalData.providerSpecialty}`"
        :visible.sync="showModal" :destroy-on-close="true" :before-close="closeDistributionDialog">
        <el-row style="margin-top: 20px;" :gutter="10">
          <el-col :span="5">
            <div style="margin-bottom: 5px; font-size: 14px;"><i title="Filter By EM Category" class="mdi mdi-filter-variant filterIcon"></i><label>Filter EM
                Categories:</label></div>
            <div style="height: 300px; overflow:hidden; overflow-y: auto; padding: 0px 5px 0px 0px;margin: 0px 0px 20px 0px;" element-loading-spinner="atom-audit-loader"
              v-loading="categoryDataInModalLoading">
              <div class="category-item" :class="{ 'category-item-active': category.category === emCategorySelectModal }" v-for="category in categoryDataInModal"
                :key="category.category" @click="handleModalEmCategorySelect(category.category)">
                <div style="margin-bottom: 5px;">{{ category.category }}</div>
                <span style="font-style:italic; margin: 0px 0px 0px 0px;font-size: 12px;"><i class="mdi mdi-numeric"></i> {{ category.numberOfClaims }} Claims</span>
              </div>
            </div>
            <div style="">
              <div style="margin-bottom: 0px; font-size: 14px;">
                <i title="Select Comparisons" class="mdi mdi-set-none filterIcon"></i><label>Select Comparisons:</label>
                <el-dropdown style="float: right;" trigger="click" @command="handleComparisonModalCommands">
                  <span><i title="filter actions" class="actionsAction mdi mdi-dots-horizontal" style="font-size: 18px; cursor: pointer;"></i></span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="clear">Clear Comparisons</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div style="margin: 10px 0px 0px 0px;font-size: 13px;">
                <input type="checkbox" v-model="medicareCompareModal" />
                Medicare Compare <span style="font-size: 20px;color:#fee18f;margin: -1px 0px 0px 3px;position:absolute;">●</span>
              </div>
              <div v-if="activeTab === 'distributionByProvider'" style="font-size: 13px;">
                <input type="checkbox" v-model="specialtyCompareModal" />
                Provider Specialty Compare <span style="font-size: 20px;color:#f57fc5;margin: -1px 0px 0px 3px;position:absolute;">●</span>
              </div>
              <div style="font-size: 13px;">
                <input type="checkbox" v-model="providerSpecialtiesCompareModal" @change="
                    () => {
                      if (!providerSpecialtiesCompareModal) {
                        this.providerSpecialtiesSelectModal = []
                        this.handleProviderSpecialtiesSelectModal()
                      }
                    }
                  " />
                Specialties Compare <span style="font-size: 20px;color:#7cbe5f;margin: -1px 0px 0px 3px;position:absolute;">●</span>
              </div>
              <div style="margin-top: 10px; margin-left: 5px;display: flex; width: 98%" v-show="providerSpecialtiesCompareModal">
                <div class="specialtiesFilterContainer">
                  <span v-if="!this.providerSpecialtiesSelectModal.length"><i style="margin: 0px 5px 0px 5px;font-size: 14px; font-weight: 100; color: #b4bccc;"
                      class="el-icon-star-off"></i><span style="color: #b4bccc;">Specialties</span></span>
                  <span v-html="compModalTitle"></span>
                </div>
                <div class="specialtiesFilterButton">
                  <el-button size="mini" title="add values" icon="el-icon-search" @click="openSpecialtiesCompareByType()"></el-button>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="19" element-loading-spinner="atom-audit-loader" v-loading="modalDataLoading">
            <el-col :span="12">
              <FrequencyTable :seriesData="modalData.seriesData" :medicareCompare="medicareCompareModal" :specialtyCompare="specialtyCompareModal"
                :providerSpecialtiesCompare="providerSpecialtiesCompareModal && providerSpecialtiesSelectModal.length"></FrequencyTable>
            </el-col>
            <el-col :span="12">
              <ProRiskAnalysisChart :seriesData="modalData.seriesData" :medicareCompare="medicareCompareModal" :specialtyCompare="specialtyCompareModal"
                :providerSpecialtiesCompare="providerSpecialtiesCompareModal && providerSpecialtiesSelectModal.length" :type="modalData"
                :providerName="activeTab === 'distributionByProvider' ? modalData.providerName : activeTab === 'distributionByServiceLocation' ? modalData.serviceLocation : modalData.providerSpecialty">
              </ProRiskAnalysisChart>
            </el-col>
          </el-col>
        </el-row>

        <el-row style="margin-top: 20px;">
          <el-row :gutter="20">
            <el-col :span="12">
              <h3 class="sectionHeader">
                <div>Create Audit Sample</div>
                <el-button-group style="margin-top: -25px; float: right;">
                  <el-button class="hiaButton" plain round size="mini" @click="addSelected">Add Selected</el-button>
                  <el-button class="hiaButton" plain round size="mini" @click="openRandomSamplePopover">Generate Random Sample</el-button>
                </el-button-group>
                <el-switch class="categoryToggle" v-model="proRiskRecordsDataAll" active-text="Current Category" inactive-text="All Categories"
                  @change="getProRiskRecordsDataAll"> </el-switch>

                <el-popover placement="top" ref="importRandomPopover" title="Generate Random Sample" width="400">
                  <el-form size="mini" id="addForm" :inline="true" ref="addForm" label-position="left" label-width="180px" v-loading="isPosting"
                    element-loading-spinner="atom-audit-loader">
                    <el-form-item prop="sampleSize" label="Random Sample Size">
                      <el-input style="width: 75%" v-model="sampleForm.size" size="mini"></el-input>
                    </el-form-item>

                    <center>
                      <el-button-group style="margin-top: 10px;">
                        <el-button class="hiaButton" plain round type="text" size="mini" @click="closeRandomSamplePopover">Cancel</el-button>
                        <el-button class="hiaButton" round :disabled="!sampleForm.project" size="mini" @click="getProRiskRecordsDataRandom">Generate </el-button>
                      </el-button-group>
                    </center>
                  </el-form>
                </el-popover>
              </h3>

              <el-card>
                <v-server-table style="min-height: 120px;" ref="proRiskRecordsGrid" id="proRiskRecordsGrid" :columns="proRiskRecordColumns" :options="proRiskRecordOptions"
                  class="hiaTable" element-loading-spinner="atom-audit-loader" @loading="proRecordTableLoader = true" @loaded="proRecordTableLoader = false"
                  v-loading="proRecordTableLoader">
                  <template slot="selected" slot-scope="props">
                    <input type="checkbox" v-model="props.row.selected" @click="toggleSelection(props.row, $event)" />
                  </template>
                  <template slot="dateOfService" slot-scope="props">
                    <div>
                      {{ props.row.dateOfService ? formatDate(props.row.dateOfService) : '' }}
                    </div>
                  </template>
                </v-server-table>
              </el-card>
            </el-col>
            <el-col :span="12">
              <h3 class="sectionHeader">
                Sampled Records ({{ sampleForm.claims.length }})
                <el-button-group style="margin-top: -10px; float: right;">
                  <el-button class="hiaButton" plain round size="mini" @click="sampleForm.claims = []">Clear Sample</el-button>
                  <el-button class="hiaButton" plain round size="mini" :disabled="sampleForm.claims.length === 0" @click="openImportSamplePopover">Import Sample</el-button>
                </el-button-group>

                <el-popover placement="top" ref="importSamplePopover" title="Import Sample into Project" width="400">
                  <el-form size="mini" id="addForm" :inline="true" ref="addForm" label-position="left" label-width="180px" v-loading="isPosting"
                    element-loading-spinner="atom-audit-loader">
                    <el-form-item prop="targetProject" label="Target Project">
                      <projectSelector style="margin-top: 10px; width: 350px;" :allowAdd="true" :clearable="false" @selected="sampleForm.project = $event"
                        v-model="sampleForm.projectId" :project="sampleForm.project" :defaultQuery="{ projectTypeId: 1002 }"> </projectSelector>
                    </el-form-item>

                    <center>
                      <el-button-group style="margin-top: 10px;">
                        <el-button class="hiaButton" plain round type="text" size="mini" @click="closeImportSamplePopover">Cancel</el-button>
                        <el-button class="hiaButton" round :disabled="!sampleForm.project" size="mini" @click="importRecords">Import </el-button>
                      </el-button-group>
                    </center>
                  </el-form>
                </el-popover>
              </h3>

              <el-card>
                <v-client-table class="hiaTable" ref="sampleGrid" id="sampleGrid" :data="sampleForm.claims" :columns="sampleColumns" :options="sampleOptions">
                  <template slot="dateOfService" slot-scope="props">
                    <div>
                      {{ props.row.dateOfService ? formatDate(props.row.dateOfService) : '' }}
                    </div>
                  </template>
                  <template slot="delete" slot-scope="props">
                    <div style="text-align: center;">
                      <i class="el-icon-close delete-button" title="Delete Claim" @click="deleteClaim(props.row)"></i>
                    </div>
                  </template>
                </v-client-table>
              </el-card>
            </el-col>
          </el-row>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'
  // import processFile from '@/utilities/process837p'
  import projectSelector from '@/components/controls/projectSelector'
  import { format, parseISO } from 'date-fns'
  import dateHelper from '@/mixins/date-helper'
  import SpecialtiesCompareDialog from './SpecialtiesCompareDialog'
  import HiaAuthorization from '@/mixins/hiaAuthorization'
  import hiaTable from '@/mixins/hiaTable'
  import FrequencyTable from './FrequencyTable'
  import ProRiskAnalysisChart from './ProRiskAnalysisChart'
  import debounce from 'debounce'

  export default {
    name: 'ProRiskAnalysis',
    mixins: [dateHelper, HiaAuthorization, hiaTable],
    data: function () {
      return {
        format: format,
        parseISO: parseISO,
        categoryData: [],
        claims: [],
        providers: [],
        highCharts: null,
        activeTab: 'distributionByProvider',
        isPosting: false,
        showSpecialtiesCompareModal: false,
        showSpecialtiesCompareModalByType: false,
        specialties: [],
        specialtiesSelectList: [],
        providersSelectList: [],
        locationsSelectList: [],
        providerTableLoader: false,
        specialtyTableLoader: false,
        proRecordTableLoader: false,
        vueTables2Query: {},
        proRiskRecordsGridQuery: {},
        providerColumns: ['seriesChart'],
        providerOptions: {
          requestFunction: data => {
            return this.requestDistributionByProviderData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: [],
          orderBy: {
            column: 'providerName',
            ascending: true
          },
          columnsClasses: {
            // seriesChart: 'fiftyPercent'
            // frequencyTable: 'twentyPercent'
          },
          headings: {
            providerName: 'Provider',
            providerSpecialty: 'Specialty',
            claimsTotal: 'Claims',
            newPatient: 'New Patient Codes',
            establishedPatient: 'Established Patient Codes',
            newPatientChart: 'New Patient EM Level Distribution',
            establishedPatientChart: 'Established Patient EM Level Distribution',
            selected: '',
            seriesChart: '',
            frequencyTable: 'Frequency Table'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        modalData: {},
        showModal: false,
        sampleForm: {
          size: 10,
          projectId: null,
          project: {},
          claims: []
        },
        specialtyColumns: ['seriesChart'],
        specialtyOptions: {
          requestFunction: data => {
            return this.requestDistributionBySpecialtyData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: [],
          orderBy: {
            column: 'specialty',
            ascending: true
          },
          columnsClasses: {
            newPatientChart: 'twentyPercent',
            establishedPatientChart: 'twentyPercent'
          },
          headings: {
            providerSpecialty: 'Specialty',
            claimsTotal: 'Claims',
            newPatient: 'New Patient Codes',
            establishedPatient: 'Established Patient Codes',
            newPatientChart: 'New Patient EM Level Distribution',
            establishedPatientChart: 'Established Patient EM Level Distribution',
            selected: '',
            seriesChart: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        proRiskRecordColumns: ['selected', 'claimId', 'dateOfService', 'dxCodesDisplay', 'nonEmCodesDisplay', 'emCodesDisplay'],
        proRiskRecordOptions: {
          requestFunction: data => {
            return this.requestProRiskRecordsData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: ['claimId', 'dateOfService', 'dxCodesDisplay', 'nonEmCodesDisplay', 'emCodesDisplay'],
          orderBy: {
            column: 'claimId',
            ascending: true
          },
          columnsClasses: {
            // newPatientChart: 'twentyPercent',
            // establishedPatientChart: 'twentyPercent'
          },
          headings: {
            nonEmCodesDisplay: 'CPTs',
            emCodesDisplay: 'EMs',
            claimId: 'claim Id',
            dateOfService: 'Date of Service',
            dxCodesDisplay: 'Dxs',
            cptCodesDisplay: 'CPTs',
            emLevel: 'EM Level',
            selected: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        sampleColumns: ['claimId', 'dateOfService', 'dxCodesDisplay', 'nonEmCodesDisplay', 'emCodesDisplay', 'delete'],
        sampleOptions: {
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: ['claimId', 'dateOfService', 'dxCodesDisplay', 'nonEmCodesDisplay', 'emCodesDisplay', 'emLevel'],
          orderBy: {
            column: 'claimId',
            ascending: true
          },
          columnsClasses: {
            // newPatientChart: 'twentyPercent',
            // establishedPatientChart: 'twentyPercent'
          },
          headings: {
            nonEmCodesDisplay: 'CPTs',
            emCodesDisplay: 'EMs',
            claimId: 'claim Id',
            dateOfService: 'Date of Service',
            dxCodesDisplay: 'Dxs',
            cptCodesDisplay: 'CPTs',
            emLevel: 'EM Level',
            delete: ''
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        localdateRange: [],
        overallDistribution: {
          count: 0,
          data: {
            claimsTotal: 0,
            providerName: 'overall',
            seriesData: [
              {
                seriesTitle: 'Overall',
                seriesType: 'category',
                labels: ['99202', '99203', '99204', '99205'],
                frequencies: [1, 2, 3, 4],
                percentages: [0.18167661562418894, 13.80742278743836, 41.31845315338697, 44.69244744355048]
              }
            ]
          },
          newPatient: {},
          establishedPatient: {}
        },
        localProviderDistribution: [],
        localSpecialtyDistribution: [],
        localProRiskRecords: {
          count: 0,
          data: [],
          selections: []
        },
        selectionData: [],
        emCategorySelect: 'Office or Other Outpatient Services - New Patient',
        providerSpecialtiesSelect: [],
        providersFilter: [],
        providerSpecialtiesFilter: [],
        serviceLocationsFilter: [],
        showProviderSpecialtiesFilterModal: false,
        showProvidersFilterModal: false,
        showLocationsFilterModal: false,
        medicareCompare: false,
        specialtyCompare: false,
        providerSpecialtiesCompare: false,
        providerNameCriteria: '',
        providerSpecialtyCriteria: '',
        overallLoading: false,
        categoryDataLoading: false,
        providerGlobalSearch: null,
        providerSpecialtyGlobalSearch: null,
        categoryDataInModal: [],
        categoryDataInModalLoading: false,
        modalDataLoading: false,
        emCategorySelectModal: null,
        proRiskRecordsDataAll: true,
        medicareCompareModal: false,
        specialtyCompareModal: false,
        providerSpecialtiesCompareModal: false,
        providerSpecialtiesSelectModal: [],
        serviceLocationTableLoader: false,
        serviceLocationColumns: ['seriesChart'],
        serviceLocationOptions: {
          requestFunction: data => {
            return this.requestDistributionByServiceLocationData(data)
          },
          debounce: 600,
          filterByColumn: true,
          listColumns: {
            // reviewLevel: []
          },
          sortable: [],
          orderBy: {
            column: 'serviceLocation',
            ascending: true
          },
          columnsClasses: {
            // seriesChart: 'fiftyPercent'
            // frequencyTable: 'twentyPercent'
          },
          headings: {
            providerName: 'Provider',
            providerSpecialty: 'Specialty',
            claimsTotal: 'Claims',
            newPatient: 'New Patient Codes',
            establishedPatient: 'Established Patient Codes',
            newPatientChart: 'New Patient EM Level Distribution',
            establishedPatientChart: 'Established Patient EM Level Distribution',
            selected: '',
            seriesChart: '',
            serviceLocation: 'serviceLocation',
            frequencyTable: 'Frequency Table'
          },
          perPage: 10,
          texts: {
            filter: '',
            filterBy: '...'
          },
          highlightMatches: true,
          sortIcon: { is: 'none' }
          // columnsDropdown: true
        },
        serviceLocationGlobalSearch: null,
        filterDataLoading: false
      }
    },
    components: { projectSelector, FrequencyTable, ProRiskAnalysisChart, SpecialtiesCompareDialog },
    methods: {
      ...mapActions('proReviews/', ['POST_PRO_TEMPLATE_MULTIPLE', 'LOAD_DISTRIBUTION_BY_PROVIDER_LIST_PAGINATED2', 'LOAD_DISTRIBUTION_BY_PROVIDER_LIST_PAGINATED3', 'LOAD_DISTRIBUTION_BY_SPECIALTY_LIST_PAGINATED2', 'LOAD_OVERALL_DISTRIBUTION2', 'LOAD_PRO_RISK_RECORD_RANDOM_LIST', 'GET_CATEGORY_DATA', 'GET_CATEGORY_DATA_BY_PROVIDER', 'GET_CATEGORY_DATA_BY_SPECIALTY', 'LOAD_PRO_RISK_RECORD_LIST_PAGINATED2', 'LOAD_DISTRIBUTION_BY_LOCATION_LIST_PAGINATED2', 'GET_CATEGORY_DATA_BY_SERVICELOCATION', 'GET_SPECIALTIES_FOR_FILTER', 'GET_PROVIDERS_FOR_FILTER', 'GET_SERVICELOCATIONS_FOR_FILTER']),
      ...mapMutations('globals/', ['SET_DATERANGE']),
      async addSelected() {
        this.sampleForm.claims = this.selectionData.filter(x => x.selected)
      },
      openImportSamplePopover(e) {
        const popper = this.$refs.importSamplePopover
        this.popperClickTarget = e.target
        popper.doDestroy()
        popper.referenceElm = e.target
        popper.doShow()
      },
      closeImportSamplePopover() {
        const popper = this.$refs.importSamplePopover
        if (popper) {
          popper.doDestroy()
          popper.doClose()
        }
      },
      toggleSelection(row, e) {
        setTimeout(() => {
          if (row.selected) {
            this.addSelection(row)
          } else {
            this.deleteSelection(row)
          }
        }, 5)
      },
      addSelection(row) {
        this.selectionData.push(row)
      },
      deleteSelection(row) {
        const index = this.selectionData.findIndex(f => f.recordId === row.recordId)
        if (index !== -1) {
          this.selectionData.splice(index, 1)
          const sourceIndex = this.$refs.proRiskRecordsGrid.data.findIndex(f => f.recordId === row.recordId)
          if (sourceIndex !== -1) {
            this.$refs.proRiskRecordsGrid.data[sourceIndex].selected = false
          }
        }
      },
      async handleShowModalAll() {
        const modalDataAll = this.overallDistribution.data
        modalDataAll.providerName = 'overall'
        modalDataAll.providerSpecialty = 'overall'
        await this.handleShowModal(modalDataAll)
      },
      async handleShowModal(provider) {
        this.modalData = provider
        this.emCategorySelectModal = this.emCategorySelect
        this.showModal = true
        const dateRange = this.GET_DATERANGE()
        if (this.activeTab === 'distributionByProvider') {
          this.modalData.groupBy = 'provider'
          const payload = {
            dateRange: dateRange,
            providerName: provider.providerName
          }
          this.categoryDataInModalLoading = true
          this.categoryDataInModal = await this.GET_CATEGORY_DATA_BY_PROVIDER(payload)
          this.categoryDataInModalLoading = false
        }

        if (this.activeTab === 'distributionBySpecialty') {
          this.modalData.groupBy = 'specialty'
          const payload = {
            dateRange: dateRange,
            specialty: provider.providerTaxonomy
          }
          this.categoryDataInModalLoading = true
          this.categoryDataInModal = await this.GET_CATEGORY_DATA_BY_SPECIALTY(payload)
          this.categoryDataInModalLoading = false
        }

        if (this.activeTab === 'distributionByServiceLocation') {
          this.modalData.groupBy = 'serviceLocation'
          const payload = {
            dateRange: dateRange,
            serviceLocation: provider.serviceLocation
          }
          this.categoryDataInModalLoading = true
          this.categoryDataInModal = await this.GET_CATEGORY_DATA_BY_SERVICELOCATION(payload)
          this.categoryDataInModalLoading = false
        }

        if (this.activeTab === 'overallDistribution') {
          this.modalData.groupBy = 'overall'
          const payload = {
            dateRange: dateRange,
            providerName: provider.providerName
          }
          this.categoryDataInModalLoading = true
          this.categoryDataInModal = await this.GET_CATEGORY_DATA_BY_PROVIDER(payload)
          this.categoryDataInModalLoading = false
        }
      },
      async importRecords() {
        const importList = this.sampleForm.claims.map(x => {
          return {
            patientNumber: x.claimId,
            dateOfService: x.dateOfService,
            provider: x.providerName,
            projectId: this.sampleForm.projectId,
            dxs: x.dxCodes.map((y, i) => {
              return { originalDx: y.originalDx, sequence: i + 1 }
            }),
            cpts: x.cptCodes.map((y, i) => {
              let mods = []
              if (y.proRiskAnalysisModifiers.length) {
                mods = y.proRiskAnalysisModifiers.map((z, j) => {
                  return { originalModifier: z.originalModifier }
                })
              }
              return { originalCpt: y.originalCpt, sequence: i + 1, modifiers: mods }
            })
          }
        })

        await this.POST_PRO_TEMPLATE_MULTIPLE(importList)
        this.$message.info('Records Imported Successfully')
      },
      async handleCriteriaChange() {
        try {
          const filters = [this.providerSpecialtiesSelect, this.providerSpecialtiesFilter, this.providersFilter, this.serviceLocationsFilter, this.providerSpecialtiesSelectModal]

          if (filters.some(filter => filter.length > 0)) {
            await this.$confirm('Changing the Date Range will clear all filters.  Continue?', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            })
          }
        } catch (e) {
          const currentDateRange = this.GET_DATERANGE()
          this.localdateRange = [currentDateRange.startDate, currentDateRange.endDate]
          console.log(e)
          return
        }
        await this.resetFilterData()
        this.localdateRange = this.localdateRange || []
        this.SET_DATERANGE(this.localdateRange)
        this.categoryDataLoading = true
        this.categoryData = await this.GET_CATEGORY_DATA({ dateRange: this.GET_DATERANGE() })
        this.categoryDataLoading = false
        this.$refs.distributionProvidersGrid?.getData()
        this.$refs.distributionSpecialtiesGrid?.getData()
        this.$refs.distributionServiceLocationGrid?.getData()
        this.getOverallDistribution()
      },
      async requestDistributionByProviderData(data) {
        try {
          data.dateRange = this.GET_DATERANGE()
          data.category = this.emCategorySelect
          data.specialties = this.providerSpecialtiesSelect
          data.providersFilter = this.providersFilter
          data.providerSpecialtiesFilter = this.providerSpecialtiesFilter
          data.serviceLocationsFilter = this.serviceLocationsFilter
          data.globalSearch = this.providerGlobalSearch
          data.query.providerName = this.providerNameCriteria
          data.query.providerSpecialty = this.providerSpecialtyCriteria
          this.vueTables2Query = data
          const resultArray = await Promise.all([this.LOAD_DISTRIBUTION_BY_PROVIDER_LIST_PAGINATED3(data)])
          this.localProviderDistribution = resultArray[0].data
          return resultArray[0]
        } catch (err) {
          console.log(err)
        }
      },
      async requestDistributionBySpecialtyData(data) {
        try {
          data.dateRange = this.GET_DATERANGE()
          data.category = this.emCategorySelect
          data.specialties = this.providerSpecialtiesSelect
          data.providersFilter = this.providersFilter
          data.providerSpecialtiesFilter = this.providerSpecialtiesFilter
          data.serviceLocationsFilter = this.serviceLocationsFilter
          data.globalSearch = this.providerSpecialtyGlobalSearch
          data.query.providerSpecialty = this.providerSpecialtyCriteria
          this.vueTables2Query = data
          const resultArray = await Promise.all([this.LOAD_DISTRIBUTION_BY_SPECIALTY_LIST_PAGINATED2(data)])
          this.localSpecialtyDistribution = resultArray[0].data
          return resultArray[0]
        } catch (err) {
          console.log(err)
        }
      },
      async getOverallDistribution() {
        try {
          const payload = {
            dateRange: this.GET_DATERANGE(),
            category: this.emCategorySelect,
            specialties: this.providerSpecialtiesSelect,
            providersFilter: this.providersFilter,
            providerSpecialtiesFilter: this.providerSpecialtiesFilter,
            serviceLocationsFilter: this.serviceLocationsFilter
          }
          this.overallLoading = true
          this.overallDistribution = await this.LOAD_OVERALL_DISTRIBUTION2(payload)
          this.overallLoading = false
        } catch (err) {
          console.log(err)
          this.overallLoading = false
        }
      },
      async requestProRiskRecordsData(data) {
        try {
          data.dateRange = this.GET_DATERANGE()
          data.providerName = this.modalData.groupBy === 'provider' ? this.modalData.providerName : null
          data.specialty = this.modalData.groupBy === 'specialty' ? this.modalData.providerTaxonomy : null
          data.groupBy = this.modalData.groupBy
          data.selections = this.selectionData.map(m => m.recordId)
          data.category = this.proRiskRecordsDataAll === false ? null : this.emCategorySelectModal
          data.serviceLocation = this.modalData.serviceLocation ? this.modalData.serviceLocation : null
          this.proRiskRecordsGridQuery = data
          const resultArray = await Promise.all([this.LOAD_PRO_RISK_RECORD_LIST_PAGINATED2(data)])
          this.localProRiskRecords = resultArray[0]
          resultArray[0].data = this.setProRiskRecordsPaginatedData(resultArray[0].data)
          return resultArray[0]
        } catch (err) {
          console.log(err)
        }
      },
      setProRiskRecordsPaginatedData(data) {
        const response = data.map(m => {
          if (this.selectionData.find(f => f.recordId === m.recordId)) {
            m.selected = true
          }
          return m
        })
        return response
      },
      deleteClaim(row) {
        const index = this.sampleForm.claims.findIndex(f => f.recordId === row.recordId)
        this.sampleForm.claims.splice(index, 1)
        this.deleteSelection(row)
      },
      closeDistributionDialog(done) {
        this.selectionData = []
        this.sampleForm = {
          size: 10,
          projectId: null,
          project: {},
          claims: []
        }
        this.medicareCompareModal = false
        this.specialtyCompareModal = false
        this.providerSpecialtiesCompareModal = false
        this.providerSpecialtiesSelectModal = []
        done()
      },
      async getProRiskRecordsDataRandom() {
        try {
          this.proRiskRecordsGridQuery.sampleSize = this.sampleForm.size
          this.proRiskRecordsGridQuery.selections = this.selectionData.map(m => m.recordId)
          const resultArray = await Promise.all([this.LOAD_PRO_RISK_RECORD_RANDOM_LIST(this.proRiskRecordsGridQuery)])
          this.selectionData = this.selectionData.concat(resultArray[0])
          this.sampleForm.claims = this.sampleForm.claims.concat(resultArray[0])
          this.setProRiskRecordsPaginatedRandomData(resultArray[0])
          this.closeRandomSamplePopover()
        } catch (err) {
          console.log(err)
          this.closeRandomSamplePopover()
        }
      },
      setProRiskRecordsPaginatedRandomData(data) {
        data.forEach((v, i) => {
          const sourceIndex = this.$refs.proRiskRecordsGrid.data.findIndex(f => f.recordId === v.recordId)
          if (sourceIndex !== -1) {
            this.$refs.proRiskRecordsGrid.data[sourceIndex].selected = true
          }
        })
      },
      handleEmCategorySelect(category) {
        this.emCategorySelect = category
        this.$refs.distributionProvidersGrid?.getData()
        this.$refs.distributionSpecialtiesGrid?.getData()
        this.$refs.distributionServiceLocationGrid?.getData()
        this.getOverallDistribution()
      },
      handleApplySpecialtiesCompare(e) {
        this.showSpecialtiesCompareModal = false
        this.providerSpecialtiesSelect = e
        this.$refs.distributionProvidersGrid?.getData()
        this.$refs.distributionSpecialtiesGrid?.getData()
        this.$refs.distributionServiceLocationGrid?.getData()
        this.getOverallDistribution()
      },
      handleApplySpecialtiesCompareByType(e) {
        this.showSpecialtiesCompareModalByType = false
        this.providerSpecialtiesSelectModal = e
        this.handleModalEmCategorySelect(this.emCategorySelectModal)
      },
      handleCloseSpecialtiesModalByType() {
        this.showSpecialtiesCompareModalByType = false
      },
      handleApplyProviderSpecialtiesFilter(e) {
        this.showProviderSpecialtiesFilterModal = false
        this.providerSpecialtiesFilter = e
        this.$refs.distributionProvidersGrid?.getData()
        this.$refs.distributionSpecialtiesGrid?.getData()
        this.$refs.distributionServiceLocationGrid?.getData()
        this.getOverallDistribution()
      },
      handleCloseProviderSpecialtiesFilter() {
        this.showProviderSpecialtiesFilterModal = false
      },
      handleApplyProvidersFilter(e) {
        this.showProvidersFilterModal = false
        this.providersFilter = e
        this.$refs.distributionProvidersGrid?.getData()
        this.$refs.distributionSpecialtiesGrid?.getData()
        this.$refs.distributionServiceLocationGrid?.getData()
        this.getOverallDistribution()
      },
      handleCloseProvidersFilter() {
        this.showProvidersFilterModal = false
      },
      handleApplyLocationsFilter(e) {
        this.showLocationsFilterModal = false
        this.serviceLocationsFilter = e
        this.$refs.distributionProvidersGrid?.getData()
        this.$refs.distributionSpecialtiesGrid?.getData()
        this.$refs.distributionServiceLocationGrid?.getData()
        this.getOverallDistribution()
      },
      handleCloseLocationsFilter() {
        this.showLocationsFilterModal = false
      },
      handleCloseSpecialtiesModal() {
        this.showSpecialtiesCompareModal = false
      },
      handleProviderSpecialtiesSelect(isOpenEvent) {
        this.$refs.distributionProvidersGrid?.getData()
        this.$refs.distributionSpecialtiesGrid?.getData()
        this.$refs.distributionServiceLocationGrid?.getData()
        this.getOverallDistribution()
      },
      handleProvidersFilter(isOpenEvent) {
        this.$refs.distributionProvidersGrid?.setPage(1)
        this.$refs.distributionSpecialtiesGrid?.setPage(1)
        this.$refs.distributionServiceLocationGrid?.setPage(1)
        this.getOverallDistribution()
      },
      handleProviderSpecialtiesFilter(isOpenEvent) {
        this.$refs.distributionProvidersGrid?.setPage(1)
        this.$refs.distributionSpecialtiesGrid?.setPage(1)
        this.$refs.distributionServiceLocationGrid?.setPage(1)
        this.getOverallDistribution()
      },
      handleServiceLocationsFilter(isOpenEvent) {
        this.$refs.distributionProvidersGrid?.setPage(1)
        this.$refs.distributionSpecialtiesGrid?.setPage(1)
        this.$refs.distributionServiceLocationGrid?.setPage(1)
        this.getOverallDistribution()
      },
      handleFilterCommands(command) {
        if (command === 'clear') {
          this.providersFilter = []
          this.providerSpecialtiesFilter = []
          this.serviceLocationsFilter = []
          this.$refs.distributionProvidersGrid?.setPage(1)
          this.$refs.distributionSpecialtiesGrid?.setPage(1)
          this.$refs.distributionServiceLocationGrid?.setPage(1)
          this.getOverallDistribution()
        }
      },
      handleComparisonCommands(command) {
        if (command === 'clear') {
          this.medicareCompare = false
          this.specialtyCompare = false
          this.providerSpecialtiesCompare = false

          this.providerSpecialtiesSelect = []
          this.handleProviderSpecialtiesSelect()
        }
      },
      runGridFilters: debounce(function (e) {
        if (this.activeTab === 'distributionByProvider') {
          this.$refs.distributionProvidersGrid.resetQuery()
        }
        if (this.activeTab === 'distributionBySpecialty') {
          this.$refs.distributionSpecialtiesGrid.resetQuery()
        }
        if (this.activeTab === 'distributionByServiceLocation') {
          this.$refs.distributionServiceLocationGrid.resetQuery()
        }
      }, 400),
      async handleModalEmCategorySelect(category) {
        this.emCategorySelectModal = category
        if (this.activeTab === 'distributionByProvider' || this.activeTab === 'overallDistribution') {
          const payload = {
            query: { providerName: '', providerSpecialty: '' },
            limit: 10,
            ascending: 1,
            page: 1,
            byColumn: 1,
            orderBy: 'providerName',
            dateRange: this.GET_DATERANGE(),
            category: this.emCategorySelectModal,
            specialties: this.providerSpecialtiesSelectModal.length ? this.providerSpecialtiesSelectModal : [],
            providerName: this.modalData.providerName === 'overall' ? 'overall' : this.modalData.providerName,
            providersFilter: this.modalData.providerName === 'overall' ? [] : [this.modalData.providerName],
            providerSpecialtiesFilter: [],
            serviceLocationsFilter: [],
            globalSearch: null
          }
          this.modalDataLoading = true
          await this.$refs.proRiskRecordsGrid.resetQuery()
          const resultArray = this.modalData.providerName === 'overall' ? await Promise.all([this.LOAD_OVERALL_DISTRIBUTION2(payload)]) : await Promise.all([this.LOAD_DISTRIBUTION_BY_PROVIDER_LIST_PAGINATED3(payload)])
          this.modalDataLoading = false
          if (resultArray.length) {
            this.modalData = this.modalData.providerName === 'overall' ? resultArray[0].data : resultArray[0].data[0]
            this.modalData.groupBy = 'provider'
          }
        }
        if (this.activeTab === 'distributionBySpecialty') {
          const payload = {
            query: { providerSpecialty: '' },
            limit: 10,
            ascending: 1,
            page: 1,
            byColumn: 1,
            orderBy: 'specialty',
            dateRange: this.GET_DATERANGE(),
            category: this.emCategorySelectModal,
            specialties: this.providerSpecialtiesSelectModal.length ? this.providerSpecialtiesSelectModal : [],
            providersFilter: [],
            providerName: this.modalData.providerName === 'overall' ? 'overall' : null,
            providerSpecialtiesFilter: this.modalData.providerName === 'overall' ? [] : [this.modalData.providerTaxonomy],
            serviceLocationsFilter: [],
            globalSearch: null
          }
          this.modalDataLoading = true
          await this.$refs.proRiskRecordsGrid.resetQuery()
          const resultArray = this.modalData.providerName === 'overall' ? await Promise.all([this.LOAD_OVERALL_DISTRIBUTION2(payload)]) : await Promise.all([this.LOAD_DISTRIBUTION_BY_SPECIALTY_LIST_PAGINATED2(payload)])
          this.modalDataLoading = false
          if (resultArray.length) {
            this.modalData = this.modalData.providerName === 'overall' ? resultArray[0].data : resultArray[0].data[0]
            this.modalData.groupBy = 'specialty'
          }
        }
        if (this.activeTab === 'distributionByServiceLocation') {
          const payload = {
            query: { providerSpecialty: '' },
            limit: 10,
            ascending: 1,
            page: 1,
            byColumn: 1,
            orderBy: 'serviceLocation',
            dateRange: this.GET_DATERANGE(),
            category: this.emCategorySelectModal,
            specialties: this.providerSpecialtiesSelectModal.length ? this.providerSpecialtiesSelectModal : [],
            providersFilter: [],
            providerName: this.modalData.providerName === 'overall' ? 'overall' : null,
            providerSpecialtiesFilter: this.modalData.providerName === 'overall' ? [] : [],
            serviceLocationsFilter: this.modalData.serviceLocation ? [this.modalData.serviceLocation] : [],
            globalSearch: null
          }
          this.modalDataLoading = true
          await this.$refs.proRiskRecordsGrid.resetQuery()
          const resultArray = this.modalData.providerName === 'overall' ? await Promise.all([this.LOAD_OVERALL_DISTRIBUTION2(payload)]) : await Promise.all([this.LOAD_DISTRIBUTION_BY_LOCATION_LIST_PAGINATED2(payload)])
          this.modalDataLoading = false
          if (resultArray.length) {
            this.modalData = this.modalData.providerName === 'overall' ? resultArray[0].data : resultArray[0].data[0]
            this.modalData.groupBy = 'specialty'
          }
        }
      },
      async getProRiskRecordsDataAll() {
        await this.$refs.proRiskRecordsGrid.resetQuery()
      },
      handleProviderSpecialtiesSelectModal() {
        this.handleModalEmCategorySelect(this.emCategorySelectModal)
      },
      handleComparisonModalCommands(command) {
        if (command === 'clear') {
          this.medicareCompareModal = false
          this.specialtyCompareModal = false
          this.providerSpecialtiesCompareModal = false

          this.providerSpecialtiesSelectModal = []
          this.handleProviderSpecialtiesSelectModal()
        }
      },
      async requestDistributionByServiceLocationData(data) {
        try {
          data.dateRange = this.GET_DATERANGE()
          data.category = this.emCategorySelect
          data.specialties = this.providerSpecialtiesSelect
          data.providersFilter = this.providersFilter
          data.providerSpecialtiesFilter = this.providerSpecialtiesFilter
          data.serviceLocationsFilter = this.serviceLocationsFilter
          data.globalSearch = this.serviceLocationGlobalSearch
          data.query.providerName = this.providerNameCriteria
          data.query.providerSpecialty = this.providerSpecialtyCriteria
          this.vueTables2Query = data
          const resultArray = await Promise.all([this.LOAD_DISTRIBUTION_BY_LOCATION_LIST_PAGINATED2(data)])
          this.localProviderDistribution = resultArray[0].data
          return resultArray[0]
        } catch (err) {
          console.log(err)
        }
      },
      openRandomSamplePopover(e) {
        const popper = this.$refs.importRandomPopover
        this.popperClickTarget = e.target
        popper.doDestroy()
        popper.referenceElm = e.target
        popper.doShow()
      },
      closeRandomSamplePopover() {
        const popper = this.$refs.importRandomPopover
        if (popper) {
          popper.doDestroy()
          popper.doClose()
        }
      },
      openProviderFilters() {
        this.showProvidersFilterModal = true

        if (this.providersSelectList.length === 0) {
          try {
            this.filterDataLoading = true
            const dateRange = this.GET_DATERANGE()
            const payload = {
              dateRange: dateRange,
              category: this.emCategorySelect
            }
            Promise.all([this.GET_PROVIDERS_FOR_FILTER(payload)]).then(response => {
              this.providersSelectList = response[0]
              this.filterDataLoading = false
            })
          } catch (err) {
            console.log(err)
            this.filterDataLoading = false
          }
        }
      },
      openSpecialtiesFilters() {
        if (this.specialtiesSelectList.length === 0) {
          try {
            this.filterDataLoading = true
            const dateRange = this.GET_DATERANGE()
            const payload = {
              dateRange: dateRange,
              category: this.emCategorySelect
            }
            Promise.all([this.GET_SPECIALTIES_FOR_FILTER(payload)]).then(response => {
              this.specialtiesSelectList = response[0]
              this.filterDataLoading = false
            })
          } catch (err) {
            console.log(err)
            this.filterDataLoading = false
          }
        }

        this.showProviderSpecialtiesFilterModal = true
      },
      openServiceLocationsFilters() {
        if (this.locationsSelectList.length === 0) {
          try {
            this.filterDataLoading = true
            const dateRange = this.GET_DATERANGE()
            const payload = {
              dateRange: dateRange,
              category: this.emCategorySelect
            }
            Promise.all([this.GET_SERVICELOCATIONS_FOR_FILTER(payload)]).then(response => {
              this.locationsSelectList = response[0]
              this.filterDataLoading = false
            })
          } catch (err) {
            console.log(err)
            this.filterDataLoading = false
          }
        }
        this.showLocationsFilterModal = true
      },
      openSpecialtiesCompare() {
        if (this.specialtiesSelectList.length === 0) {
          try {
            this.filterDataLoading = true
            const dateRange = this.GET_DATERANGE()
            const payload = {
              dateRange: dateRange,
              category: this.emCategorySelect
            }
            Promise.all([this.GET_SPECIALTIES_FOR_FILTER(payload)]).then(response => {
              this.specialtiesSelectList = response[0]
              this.filterDataLoading = false
            })
          } catch (err) {
            console.log(err)
            this.filterDataLoading = false
          }
        }

        this.showSpecialtiesCompareModal = true
      },
      openSpecialtiesCompareByType() {
        if (this.specialtiesSelectList.length === 0) {
          try {
            this.filterDataLoading = true
            const dateRange = this.GET_DATERANGE()
            const payload = {
              dateRange: dateRange,
              category: this.emCategorySelect
            }
            Promise.all([this.GET_SPECIALTIES_FOR_FILTER(payload)]).then(response => {
              this.specialtiesSelectList = response[0]
              this.filterDataLoading = false
            })
          } catch (err) {
            console.log(err)
            this.filterDataLoading = false
          }
        }

        this.showSpecialtiesCompareModalByType = true
      },
      async resetFilterData() {
        // filter selects
        this.providerSpecialtiesSelect = []
        this.providerSpecialtiesFilter = []
        this.providersFilter = []
        this.serviceLocationsFilter = []
        this.providerSpecialtiesSelectModal = []
        // filter datasets
        this.specialtiesSelectList = []
        this.providersSelectList = []
        this.locationsSelectList = []
      }
    },
    created: function () {
      this.localdateRange = this.newDateRange(-90, 30)
      this.SET_DATERANGE(this.localdateRange)
    },
    computed: {
      ...mapState('globals/', ['dateRangePickerConfig']),
      ...mapGetters('globals/', ['GET_DATERANGE']),
      ...mapState('user/', ['user', 'currentTenantGuid']),
      compTitle() {
        let response = ''
        if (this.providerSpecialtiesSelect) {
          this.specialtiesSelectList.forEach((v, i) => {
            if (this.providerSpecialtiesSelect.some(s => s === v.providerTaxonomy)) {
              response += `<span style="display: block; margin-bottom: 3px; width: fit-content;" class="el-tag el-tag--info el-tag--mini el-tag--light"><span class="el-select__tags-text">${v.providerSpecialty ? v.providerSpecialty : v.providerClassification}</span></span>`
            }
          })
        }
        return response
      },
      compFilterTitle() {
        let response = ''
        if (this.providerSpecialtiesFilter) {
          this.specialtiesSelectList.forEach((v, i) => {
            if (this.providerSpecialtiesFilter.some(s => s === v.providerTaxonomy)) {
              response += `<span style="display: block; margin-bottom: 3px; width: fit-content;" class="el-tag el-tag--info el-tag--mini el-tag--light"><span class="el-select__tags-text">${v.providerSpecialty ? v.providerSpecialty : v.providerClassification}</span></span>`
            }
          })
        }
        return response
      },
      compProvidersFilterTitle() {
        let response = ''
        if (this.providersFilter) {
          this.providersSelectList.forEach((v, i) => {
            if (this.providersFilter.some(s => s === v.providerName)) {
              response += `<span style="display: block; margin-bottom: 3px; width: fit-content;" class="el-tag el-tag--info el-tag--mini el-tag--light"><span class="el-select__tags-text">${v.providerName}</span></span>`
            }
          })
        }
        return response
      },
      compServiceLocationsFilterTitle() {
        let response = ''
        if (this.serviceLocationsFilter) {
          this.locationsSelectList.forEach((v, i) => {
            if (this.serviceLocationsFilter.some(s => s === v.serviceLocation)) {
              response += `<span style="display: block; margin-bottom: 3px; width: fit-content;" class="el-tag el-tag--info el-tag--mini el-tag--light"><span class="el-select__tags-text">${v.serviceLocation}</span></span>`
            }
          })
        }
        return response
      },
      compModalTitle() {
        let response = ''
        if (this.providerSpecialtiesSelectModal) {
          this.specialtiesSelectList.forEach((v, i) => {
            if (this.providerSpecialtiesSelectModal.some(s => s === v.providerTaxonomy)) {
              response += `<span style="display: block; margin-bottom: 3px; width: fit-content;" class="el-tag el-tag--info el-tag--mini el-tag--light"><span class="el-select__tags-text">${v.providerSpecialty ? v.providerSpecialty : v.providerClassification}</span></span>`
            }
          })
        }
        return response
      }
    },
    mounted: async function () {
      await import(/* webpackChunkName: "highcharts" */ 'highcharts').then(({ default: hc }) => {
        this.highCharts = hc
      })
      const dateRange = this.GET_DATERANGE()
      // const payload = {
      //    dateRange: dateRange,
      //    category: this.emCategorySelect
      // }
      this.categoryDataLoading = true
      this.categoryData = await this.GET_CATEGORY_DATA({ dateRange: dateRange })
      this.categoryDataLoading = false

      // await Promise.all([this.GET_PROVIDERS_FOR_FILTER(payload), this.GET_SPECIALTIES_FOR_FILTER(payload), this.GET_SERVICELOCATIONS_FOR_FILTER(payload)]).then(response => {
      //   this.providersSelectList = response[0]
      //   this.specialtiesSelectList = response[1]
      //   this.locationsSelectList = response[2]
      // })

      this.getOverallDistribution()
    }
  }
</script>

<style scoped>
  .category-item {
    padding: 8px 15px;
    /*background-color: #f0f0f0; */
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 6px;
    /* text-align: center; */
    cursor: pointer;
    font-size: 14px;
    color: #333;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .category-item:hover {
    /* background-color: #ddd; */
    background-color: #005695;
    /* color: #000; */
    color: #ffffff;
  }

  .category-item-active {
    /* background-color: #007bff; */
    background-color: #005695;
    color: white;
    /* font-weight: bold; */
  }

  >>>.level-row {
    display: flex;
    width: 90%;
  }

  >>>.level-cell {
    flex: 1;
    padding: 6px;
  }

  >>>.level-row:last-child .level-cell {
    border-top: 1px solid #ddd;
    border-bottom: none;
  }

  table {
    table-layout: auto;
    border-collapse: collapse;
  }

  th {
    text-align: left;
  }

  td,
  th {
    padding: 0px 10px 0px 10px;
  }

  >>>.twentyPercent {
    width: 24%;
  }

  >>>.fiftyPercent {
    width: 55%;
  }

  >>>.highcharts-credits {
    display: none;
  }

  .clickable {
    color: #337ab7;
    cursor: pointer;
    background-color: #e6eef4;
    padding: 2px;
    border-radius: 5px;
    display: inline-block;
    height: 15px;
    width: 15px;
    margin-left: 5px;
    text-align: center;
    border: 1px solid #ccddea;
    font-size: 14px;
  }

  .sectionHeader {
    padding-bottom: 5px;
    margin: 30px 0px 15px 0px;
    border-bottom: 1px solid #eee;
    font-weight: 400;
    line-height: 1;
    color: rgb(119, 119, 119);
  }

  >>>.filterIcon {
    float: left;
    font-size: 18px;
    padding-right: 0px;
    margin: -3px 5px 0px 0px;
  }

  /* highcharts background */
  .multiSelect>>>.el-input__inner {
    min-height: 30px !important;
  }

  >>>.highcharts-dashboards,
  >>>.highcharts-dashboards-wrapper {
    background-color: transparent !important;
    overflow-x: auto;
    padding: 10px 0px 10px 0px;
  }

  >>>#distributionProvidersGrid .highcharts-dashboards,
  >>>#distributionProvidersGrid .highcharts-dashboards-wrapper {
    background-color: transparent !important;
    overflow-x: auto;
    padding: 10px 0px 10px 0px;
    min-height: 200px;
  }

  >>>#distributionProvidersGrid .highcharts-dashboards-row,
  >>>#distributionSpecialtiesGrid .highcharts-dashboards-row {
    height: 200px !important;
    min-height: 200px;
  }

  >>>#distributionProvidersGrid .highcharts-dashboards-component,
  >>>#distributionSpecialtiesGrid .highcharts-dashboards-component {
    height: 200px !important;
  }

  >>>.highcharts-dashboards-component {
    box-shadow: unset !important;
    background-color: transparent;
  }

  >>>.highcharts-datagrid-outer-container {
    background-color: transparent;
  }

  >>>.highcharts-datagrid-container {
    border: 0px solid transparent;
    border-radius: 20px;
  }

  >>>.highcharts-datagrid-column-header {
    font-family: 'Roboto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    color: #606266;
    font-weight: 400;
  }

  >>>.highcharts-datagrid-column-headers {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }

  >>>#distributionProvidersGrid .highcharts-dashboards-component-title,
  >>>#distributionSpecialtiesGrid .highcharts-dashboards-component-title {
    font-size: 13px;
    font-weight: bold;
  }

  >>>#distributionProvidersGrid table.VueTables__table>thead,
  >>>#distributionSpecialtiesGrid table.VueTables__table>thead {
    display: none;
  }

  /* page and table list values, tabs */
  >>>.sticky {
    position: sticky;
    top: 10px;
  }

  >>>.VueTables__seriesChart-filter-wrapper {
    display: none !important;
  }

  >>>.listOptions {
    float: right;
    margin: 0px 0px 0px 0px;
    position: sticky;
    overflow: unset;
    top: 0;
    z-index: 6;
    height: 40px;
    width: 250px;
  }

  >>>.dataTabs .el-tabs__header.is-top {
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
    border-bottom: 1px solid rgb(238, 238, 238);
  }

  >>>.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >>>.categoryToggle {
    float: right;
    margin-top: -16px;
    margin-right: 20px;
  }

  >>>.categoryToggle>.el-switch__core {
    height: 14px;
  }

  >>>.categoryToggle>.el-switch__core::after {
    width: 12px;
    height: 12px;
    top: 0px;
  }

  >>>.categoryToggle.is-checked>.el-switch__core::after {
    margin-left: -13px !important;
  }

  >>>.categoryToggle>.el-switch__label * {
    font-size: 12px !important;
    height: 16px;
    font-weight: 400;
  }

  /*
  >>> .categoryToggle > label:first-child > span {
    border-radius: 20px 0 0 20px !important;
  }

  >>> .categoryToggle > label:first-child.is-active > span {
    border-radius: 20px 0 0 20px !important;
  }

  >>> .categoryToggle > label:nth-child(2) > span {
    border-radius: 0 20px 20px 0 !important;
  }

  >>> .categoryToggle > label:nth-child(2).is-active > span {
    border-radius: 0 20px 20px 0 !important;
  }
*/
  >>>.specialtiesCompareBadge button {
    display: block !important;
  }

  >>>.specialtiesCompareBadge>.el-badge__content.is-fixed {
    top: 5px;
    right: 15px;
  }

  >>>.specialtiesFilterContainer {
    width: 90%;
    float: left;
    border: 1px solid #dcdfe6;
    overflow-x: hidden;
    border-radius: 4px;
    padding: 3px 5px;
    font-size: 12px;
    color: #606266;
    line-height: 1.834;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
  }

  >>>.specialtiesFilterButton {
    float: left;
  }

  >>>.specialtiesFilterButton button {
    width: 100%;
    height: 30px;
    border-radius: 4px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 0px transparent;
  }

  >>>.specialtiesFilterButton button>.el-icon-search {
    color: #c0c4cc;
  }
</style>