import { render, staticRenderFns } from "./OutpatientReviewDx.vue?vue&type=template&id=4ac22673&scoped=true&"
import script from "./OutpatientReviewDx.vue?vue&type=script&lang=js&"
export * from "./OutpatientReviewDx.vue?vue&type=script&lang=js&"
import style0 from "./OutpatientReviewDx.vue?vue&type=style&index=0&id=4ac22673&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac22673",
  null
  
)

export default component.exports